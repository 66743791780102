<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Plan</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">

                    <div class="p-d-flex ">
                        <Dropdown id="plan_name" v-model="plan_name" :options="planList" :disabled="disabledplandropdown"
                            @change="getPlanId($event)" optionLabel="label" appendTo="body" class="p-text-capitalize"
                            placeholder="Select Plan" />
                        <div class="p-ml-3 p-mt-3 p-text-center" style="color:red" v-if="plan_name == ''">Please Select Plan
                        </div>
                    </div>
                    <!-- <Button :disabled="plan_name == ''" label="Request For Change Plan" icon="pi pi-plus" class=" p-mr-2"
                        @click="onRequestPlan(plan_name)" /> -->
                </div>
                <div>
                    <div class="p-d-flex p-jc-end p-mt-3" v-if="!disabledplandropdown">
                        <Button v-if="!showLoader" label="Save" icon="pi pi-check" class="p-button-outlined"
                            @click="createClientPlanComponent" style="width: 90px"></Button>
                        <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                            class="p-button-text p-ml-auto" style="width: 90px"></Button>
                    </div>
                </div>
                <DataTable v-if="plan_name != ''" :value="plancomponentList" :lazy="true" :paginator="false" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users p-mt-3"
                    data-key="bc1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="name" header="Component Name" headerStyle="width: 35%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.component_name ? data.component_name : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Client Specific Extra Charges" headerStyle="width: 22%">
                        <template #body="{ data }">
                            <div v-if="data.additional_rate < data.additional_rate_for_client" class="text-color-danger">
                                 {{ formatCurrency(data.additional_rate_for_client) }}</div>
                            <div v-if="data.additional_rate > data.additional_rate_for_client" class="text-color-success">
                                 {{ formatCurrency(data.additional_rate_for_client) }}</div>
                            <div v-if="data.additional_rate == data.additional_rate_for_client" class="text-color-danger">
                                 {{ formatCurrency(data.additional_rate_for_client) }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 13%" v-if="this.isExistComponents > 0">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-0"
                                @click="editClientPlanDetailsDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- Request Plan Dialog starts here -->
    <!-- <Dialog v-model:visible="isRequestPlan" :style="{ width: '70vw' }" modal class="p-fluid"
        header="Request For Change Plan">
        <div style="position: relative;" v-if="!showLoader">
            <div class="p-pt-2 p-px-5">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-d-flex p-field p-col-6 p-md-12">
                        <h6 class="p-m-0 ">Select Plan:</h6>
                        <Dropdown id="select-plan" class="p-ml-5" v-model="changeRequestPlanName" :options="newPlanList"
                            optionLabel="label" @change="getPlanIdForRequestChange" placeholder="Select Plan"
                            emptyMessage="No Records" appendTo="body" style="width: 30vw" />
                    </div>

                    <div class="p-field p-col-12 p-md-12">
                        <DataTable v-if="changeRequestPlanName" :value="requestChangePlancomponentList" :lazy="true"
                            :paginator="false" :rows="30" :totalRecords="totalRecords" :loading="loading" @page="onPage"
                            class="p-datatable-users" data-key="bd1" :rowHover="true"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" appendTo="body">
                            <template #empty> No data found. </template>
                            <template #loading> Loading data. Please wait... </template>
                            <Column header="Component Name" field="component_name" headerStyle="width: 60%"></Column>
                            <Column header="Free Count" field="included_free_value" headerStyle="width: 20%"></Column>
                            <Column header="Extra Charge" field="additional_rate" headerStyle="width: 20%"></Column>

                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!showLoader" :disabled="changeRequestPlanName == ''" label="Submit" icon="pi pi-check"
                class="p-button-text p-ml-auto" style="width: 90px;" @click="requestClientPlanComponent"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog> -->
    <!-- Request Plan Dialog end here -->

    <!-- edit plan component dialog start here -->
    <Dialog v-model:visible="editClientPlanDetailsDialogStatus" :style="{ width: '800px' }" header="Edit client plan"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">

                <div class="p-field p-col-12 p-md-6">
                    <label for="componentName">
                        Component Name
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <InputText :disabled="disabledplandropdown" id="componentName" class="p-text-capitalize"
                        v-model.trim="edit.component_name" required="true" maxlength="20" />
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="freecount">
                        Free count
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <InputText :disabled="disabledplandropdown" id="freecount" class="p-text-capitalize"
                        v-model.trim="edit.free_count" required="true" maxlength="20" />
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="extracharges">
                        Admin Specific Extra Charges
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="extracharges" class="p-text-capitalize" v-model.trim="edit.extra_charges" required="true"
                        maxlength="20" />
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="doActionClientPlanComponentUpdate" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit Template Event dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import CommonMixins from '../../mixins/common-mixins';

export default {
    mixins:[CommonMixins],
    data() {
        return {
            v$: useValidate(),
            planList: [],
            newPlanList: [],
            plancomponentList: [],
            page_no: 0,
            totalRecords: 0,
            plan_name: '',
            partyList: [],
            loading: false,
            showLoader: false,
            submitted: false,
            row_id: null,
            plan_id: '',
            existPlanComponent: 0,
            isRequestPlan: false,
            isSubmitted: false,
            changeRequestPlanName: "",
            requestChangePlancomponentList: [],
            disabledplandropdown: false,
            assignedPlanId: 0,
            editClientPlanDetailsDialogStatus: false,
            edit: {
                id: '',
                component_id: '',
                plan_id: '',
                component_name: '',
                free_count: '',
                extra_charges: '',
                additional_rate: '',
            },
            routeParam: '',
            isExistComponents: 0,
            localAdminName: '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.getMasterPlans();
        this.getExistingPlanComponents({
            page_no: this.page_no,
            clientid: this.routeParam
        })
    },
    methods: {
        // onRequestPlan(e) {
        //     this.assignedPlanId = e.value;
        //     this.isRequestPlan = true;
        //     this.changeRequestPlanName = '';
        //     this.requestChangePlancomponentList = [];
        //     this.getMasterPlansWithoutCurrentPlan();
        // },

        getMasterPlans() {
            this.ApiService.getMasterPlans().then((data) => {
                if (data.success === true) {
                    this.planList = data.data;
                }
            });
        },
        getMasterPlansWithoutCurrentPlan() {
            this.ApiService.getMasterPlans(this.assignedPlanId).then((data) => {
                if (data.success === true) {
                    this.newPlanList = data.data;
                }
            });
        },
        getExistingPlanComponents(ev) {
            this.loading = true;
            this.ApiService.getExistingPlanComponents(ev).then((data) => {
                if (data.success === true) {
                    this.plancomponentList = data.data;
                    this.plan_id = data.plan.plan_id;
                    if (data.plan == '') {
                        this.plan_name = data.plan;
                    } else {
                        this.plan_name = {
                            label: data.plan.plan_name,
                            value: data.plan.plan_id,
                        };
                        this.disabledplandropdown = true;
                    }
                    this.isExistComponents = data.count;
                    this.loading = false;
                    this.existPlanComponent = 1;
                } else {
                    this.loading = false;
                }
            });
        },


        editClientPlanDetailsDialogOpen(e) {
            this.editClientPlanDetailsDialogStatus = true;
            this.edit.id = e.id;
            this.edit.component_id = e.component_id;
            this.edit.plan_id = e.plan_id;
            this.edit.component_name = e.component_name;
            this.edit.free_count = e.included_free_value_for_client;
            this.edit.extra_charges = e.additional_rate_for_client;
            this.edit.additional_rate = e.additional_rate;

        },
        doActionClientPlanComponentUpdate() {
            this.submitted = true;
            this.v$.$validate();
            var formData = new FormData();
            formData.append('id', this.edit.id);
            formData.append('clientId', this.routeParam);
            formData.append('component_id', this.edit.component_id);
            formData.append('plan_id', this.edit.plan_id);
            formData.append('component_name', this.edit.component_name);
            formData.append('free_count', this.edit.free_count);
            formData.append('extra_charges', this.edit.extra_charges);
            formData.append('additional_rate', this.edit.additional_rate);

            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.doActionClientPlanComponentUpdate(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;

                        this.editClientPlanDetailsDialogStatus = false;
                        this.row_id = '';
                        this.edit.component_id = '';
                        this.edit.plan_id = '';
                        this.edit.component_name = '';
                        this.edit.free_count = '';
                        this.edit.extra_charges = '';
                        this.edit.additional_rate = '';
                        this.getExistingPlanComponents({
                            page_no: this.page_no,
                            clientid: this.routeParam
                        });
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.editClientPlanDetailsDialogStatus = true;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },

        getPlanId(event) {
            this.loading = true;
            this.ApiService.getPlanComponents({ plan_id: event.value.value, page_no: this.page_no, clientid: this.routeParam }).then((data) => {
                if (data.success === true) {
                    this.plancomponentList = data.data;
                    this.loading = false;
                }
            });
        },
        // getPlanIdForRequestChange(e) {
        //     this.loading = true;
        //     this.ApiService.getPlanComponents({ plan_id: e.value.value, page_no: this.page_no, clientid: this.routeParam }).then((data) => {
        //         if (data.success === true) {
        //             this.requestChangePlancomponentList = data.data;
        //             this.loading = false;
        //         }
        //     });
        // },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getPlanComponents({ page_no: event.page });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        createClientPlanComponent() {
            this.showLoader = true;
            if (this.plan_name) {
                this.ApiService.assignPlanToclient({ plan_value: JSON.stringify(this.plan_name.value), plancomponentList: this.plancomponentList, clientid: this.routeParam }).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.disabledplandropdown = true;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.getMasterPlans();
                        this.getExistingPlanComponents({
                            page_no: this.page_no,
                            clientid: this.routeParam
                        })
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });                    }
                }).catch(e => {
                    this.showLoader = false;
                    this.isRequestPlan = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
                    this.disabledplandropdown = false;
                });
            } else {
                this.showLoader = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please select the plan', life: 3000 });
            }

        },
        // requestClientPlanComponent() {
        //     this.showLoader = true;
        //     this.ApiService.getRequestForPlanChange({ oldPlanId: this.plan_id, plan_value: JSON.stringify(this.changeRequestPlanName.value), plancomponentList: this.requestChangePlancomponentList, clientid: this.routeParam }).then((items) => {
        //         if (items.status == 200) {
        //             this.showLoader = false;
        //             this.disabledplandropdown = true;
        //             var successMsg = items.message;
        //             this.isRequestPlan = false;
        //             this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
        //             this.getMasterPlans();
        //             this.getExistingPlanComponents({
        //                 page_no: this.page_no,
        //                 clientid: this.routeParam
        //             })
        //         }
        //     }).catch(e => {
        //         this.showLoader = false;
        //         this.isRequestPlan = false;
        //         this.$toast.add({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        //         this.disabledplandropdown = false;
        //     });

        // },
        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "adminconfig",
                params: { adminSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "adminaddfund",
                params: { adminSubId: this.routeParam },

            });
        }
    },
};
</script>
<style scoped lang="scss"></style>
